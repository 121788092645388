<template>
  <div class="component-container">
    <h1>Contact</h1>
    <p>Do you have a question?</p>
    <div v-if="messageSent">
      <h2>Message is sent.</h2>
    </div>
    <form @submit.prevent="handleSubmit">
      <div class="styled-input">
        <input type="text" placeholder="Name" v-model="name" required />
        <input type="email" placeholder="Email" v-model="email" required />
        <textarea
          placeholder="Your message"
          v-model="message"
          required></textarea>
      </div>
      <div class="button">
        <button type="submit">Send Message</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "ContactScreen",
  data() {
    return {
      name: "",
      email: "",
      message: "",
      messageSent: false,
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await fetch("/api/save_contact", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            message: this.message,
          }),
        });

        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.error);
        }
        this.messageSent = true;
        this.name = "";
        this.email = "";
        this.message = "";
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },
};
</script>

<style scoped>
.styled-input {
  margin-bottom: 10px;
  margin-top: 40px;
}

.styled-input ::placeholder {
  color: #92c7cf;
}

.styled-input input {
  width: calc(100% - 20px);
  padding: 10px;
  border: none;
  color: #92c7cf;
  border-bottom: 2px solid #92c7cf;
  background-color: #e5e1da;
  transition: border-color 0.3s;
}
.styled-input textarea {
  width: calc(100% - 20px);
  min-width: calc(100% - 20px);
  padding: 10px 10px 90px;
  border: none;
  color: #92c7cf;
  border-bottom: 2px solid #92c7cf;
  background-color: #e5e1da;
  transition: border-color 0.3s;
}

.styled-input input:focus {
  outline: none;
  border-color: #fbf9f1;
}
.styled-input textarea:focus {
  outline: none;
  border-color: #fbf9f1;
}
.button {
  margin-bottom: 50px;
}
</style>
