<template>
  <div id="navbar">
    <nav>
      <div class="button">
        <button @click="$emit('navigate', 'home')">Home</button>
        <button @click="$emit('navigate', 'about')">About</button>
        <button @click="$emit('navigate', 'portfolio')">Portfolio</button>
        <button @click="$emit('navigate', 'contact')">Contact</button>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",
};
</script>

<style scoped>
#navbar {
  flex-flow: row wrap;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #92c7cf;
  z-index: 1000;
}
.button {
  display: flex;
  justify-content: flex-start;
  padding-left: 50px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 2px solid #fbf9f1;
}
.button button {
  margin-right: 20px;
}
@media screen and (max-width: 600px) {
 #navbar {
  display: none;
 }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
 .button {
   flex-direction: row;
   padding-left: 50px;
   padding-right: 50px;
 }
 .button button {
   margin-right: 20px;
   margin-bottom: 0;
 }
}
</style>
