<template>
  <div id="app">
    <div id="home">
      <HomeScreen @navigate="scrollTo" />
    </div>
    <NavbarComponent @navigate="scrollTo" />
    <div id="about">
      <AboutScreen />
    </div>
    <div id="portfolio">
      <PortfolioScreen />
    </div>
    <div id="contact">
      <ContactScreen />
    </div>
  </div>
</template>

<script>
import HomeScreen from "./components/HomeScreen.vue";
import AboutScreen from "./components/AboutScreen.vue";
import PortfolioScreen from "./components/PortfolioScreen.vue";
import ContactScreen from "./components/ContactScreen.vue";
import NavbarComponent from "./components/NavbarComponent.vue";

export default {
  name: "App",
  components: {
    HomeScreen,
    AboutScreen,
    PortfolioScreen,
    ContactScreen,
    NavbarComponent,
  },
  methods: {
    scrollTo(id) {
      const element = document.getElementById(id);
      const navbar = document.getElementById('navbar');
      if (element) {
        element.style.scrollMarginTop = `${navbar.offsetHeight}px`;
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #92c7cf;
  color: #fbf9f1;
}
.component-container {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  margin: 100px 50px 0;
  padding: 0;
}

.button button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #aad7d9;
  border: 2px solid transparent;
  color: #fbf9f1;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.button button:hover {
  background-color: #fbf9f1;
  color: #aad7d9;
  border: 2px solid #aad7d9;
}
@media screen and (max-width: 600px) {
 .component-container {
   margin: 10px 20px 10px;
 }
}
</style>
