<template>
  <div class="home-container">
    <div class="frame">
      <div
        class="shape"
        :class="{ 'shape-hover': isHovered }"
        @mouseenter="startRotation"
      ></div>
      <div class="button">
        <button @click="$emit('navigate', 'about')">View my work</button>
      </div>
    </div>
    <h1>
      Hello I'm Ruben
      <br />
      Welcome to my portfolio.
    </h1>
  </div>
</template>

<script>
export default {
  name: "HomeScreen",
  data() {
    return {
      isHovered: false,
    };
  },
  methods: {
    startRotation() {
      this.isHovered = true;
    },
  },
};
</script>

<style scoped>

.home-container {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
}
.shape {
  width: 40vw;
  aspect-ratio: 1;
}

.frame {
  position: relative;
  width: 40vw;
  aspect-ratio: 1;
  border: 3px solid #fbf9f1;
  perspective: 500px;
  margin: 50px;
}
.button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button button {
  font-size: 18px;
  margin-right: 0px;
  padding: 20px 30px;
}

.shape {
  background-color: #e5e1da;
  transition: transform 2s ease;
}

.shape-hover {
  transform: rotateY(180deg);
}

@media screen and (max-width: 600px) {
  .home-container {
   justify-content: center;
 }
 .frame {
  display: none;
 }
}
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .home-container {
   justify-content: center;
 }
}
</style>
