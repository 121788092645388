<template>
  <div class="component-container">
    <h1>About</h1>
    <div class="content-container">
      <p>
        Hi there! I'm an amateur web developer with a background in data science
        and engineering. I stumbled upon coding as a hobby and became fascinated
        with the process of building things from scratch. Originally diving into
        data science, I am gradually making my way into full-stack development.
        My focus revolves around crafting clean and functional web applications
        that just work. I find joy in tackling the challenges each project
        presents, ensuring the backend runs smoothly while embracing the ongoing
        learning process.
      </p>
      <div class="content-container-col">
        <h2>Skills</h2>
      <div class="list-container">
        <div>
          <h3>Proficient</h3>
          <ul>
            <li>Python</li>
            <li>Pandas</li>
            <li>Numpy</li>
            <li>SciPy</li>
            <li>Matplotlib</li>
          </ul>
        </div>
        <div>
          <h3>Job Related</h3>
          <ul>
            <li>Azure</li>
            <li>Databricks</li>
            <li>Pyspark</li>
            <li>Sklearn</li>
            <li>Darts</li>
          </ul>
        </div>
        <div>
          <h3>Project Related</h3>
          <ul>
            <li>JavaScript</li>
            <li>HTML/CSS</li>
            <li>Docker</li>
            <li>Flask</li>
            <li>Vue</li>
            <li>React</li>
            <li>Google Cloud</li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutScreen",
};
</script>

<style scoped>
.content-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px;
  padding: 20px;
}
.content-container > * {
  flex: 1 1 0;
}
.content-container p {
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
}
.content-container-col {
  display: flex;
  flex-direction: column;
}
.list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: row;
}
@media screen and (max-width: 600px) {
 .content-container {
   padding: 0;
 }
 .content-container p {
  margin-left: 10px;
  margin-right: 10px;
 }
}
</style>
